import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Intro from '../views/Intro'
import Transactions from '../views/Transactions'
import Profile from '../views/Profile'
import Capture from '../views/Capture'
import CaptureStamp from '../views/CaptureStamp'
import FAQ from '../views/FAQ'
import NDA from '../views/NDA'
import Contact from '../views/Contact'
import Support from '../views/Support'
import ChangePassword from '../views/ChangePassword'
import Login from '../views/Login'
import Package from '../views/Package'
import PackageDetail from '../views/PackageDetail'
import Register from '../views/Register'
import AddTransaction from '../views/AddTransaction'
import Payment from '../views/Payment'
import PaymentSuccess from '../views/PaymentSuccess'
import PaymentFailed from '../views/PaymentFailed'
import PaymentUnfinished from '../views/PaymentUnfinished'
import PaymentCheckout from '../views/PaymentCheckout'

const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro,
    // redirect: { name: 'Auth' }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      transition: 'slide-left',
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Login,
    meta: {
      transition: 'slide-right',
    },
  },
  {
    path: '/package',
    name: 'Package',
    component: Package,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/package_detail/',
    name: 'Package Detail',
    component: PackageDetail,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/add_transaction',
    name: 'Add Transaction',
    component: AddTransaction,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/capture',
    name: 'Capture',
    component: Capture,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/upload-finger',
    name: 'Upload Finger',
    component: CaptureStamp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
  },
  {
    path: '/nda',
    name: 'NDA',
    component: NDA,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
  },
  {
    path: '/change_password',
    name: 'Change Password',
    component: ChangePassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/payment/checkout',
    name: 'PaymentCheckout',
    component: PaymentCheckout,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/payment/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/payment/failed',
    name: 'PaymentFailed',
    component: PaymentFailed,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/payment/unfinished',
    name: 'PaymentUnfinished',
    component: PaymentUnfinished,
    meta: {
      requiresAuth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to) => {
  store.dispatch('setMenu', to.name)
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isAuth) {
    next('/auth')
  } else {
    document.title = to.name + ' - PRiADI Apps'
    next()
  }
})

export default router
